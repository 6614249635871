.Home-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Home-logo {
    animation: Home-logo-spin infinite 20s linear;
  }
}

@keyframes Home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
